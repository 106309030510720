$green: #0e6b2d;
$yellow: #fec200;
$red: #f40005;
$white: #ffffff;
$anthrazit: #293133;

// https://www.schemecolor.com/beautiful-light-rainbow.php

$melon: #ffb5b6;
$light-orange: #fed8b3;
$very-pale-yellow: #fffec4;
$menthol: #b8f9a2;
$none-photo-blue: #95dff3;
$vodka: #c6b7f1;

$very-pale-yellow-light: lighten($very-pale-yellow, 5%);

$melon-dark: darken($melon, 20%);
$vodka-dark: darken($vodka, 20%);
$menthol-dark: darken($menthol, 20%);
$very-pale-yellow-dark: darken($very-pale-yellow, 20%);

// Pair colors

$green-light: lighten($green, 30%);
$yellow-light: lighten($yellow, 30%);
$red-light: lighten($red, 30%);
$blue-light: lighten($none-photo-blue, 10%);
$anthrazit-light: lighten($anthrazit, 30%);

$anthrazit-extra-light: lighten($anthrazit, 75%);

$green-dark: darken($green, 5%);
$yellow-dark: darken($yellow, 5%);
$red-dark: darken($red, 5%);
$blue-dark: darken($none-photo-blue, 5%);
$anthrazit-dark: lighten($anthrazit, 5%);
