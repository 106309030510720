@import "./colors.scss";

@import "~material-icons/iconfont/material-icons.css";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat-palette($mat-indigo);
$frontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat-light-theme(
  (
    color: (
      primary: $frontend-primary,
      accent: $frontend-accent,
      warn: $frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($frontend-theme);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat-palette($mat-indigo);
$frontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat-light-theme(
  (
    color: (
      primary: $frontend-primary,
      accent: $frontend-accent,
      warn: $frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($frontend-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto/Roboto-Light.ttf");
}

*,
*:focus,
*:hover {
  outline: none;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-size: "Roboto";
  font-weight: lighter !important;

  h2 {
    font-weight: lighter !important;
  }

  a {
    text-decoration: none;
    color: $melon-dark;
    &:hover {
      background-color: $melon-dark;
      color: $white;
    }
  }

  .btn {
    color: $white;
    border-radius: 5px;

    &.primary {
      background-color: $anthrazit;
    }

    &.secondary {
      background-color: $anthrazit-light;
    }

    &.tertiary {
      background-color: $yellow;
    }

    &.paypal {
      background-color: #1e477a;
      border-radius: 30px;
      margin-top: 30px;
      padding: 0 100px;
    }
  }

  .checkbox-label {
    display: flex;
    flex-direction: row;

    .mat-checkbox {
      margin: -2.5px 15px 0 0;
    }

    .span-link {
      color: $melon-dark;
      cursor: pointer;

      &:hover {
        background-color: $melon-dark;
        color: $white;
      }
    }
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $melon;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $melon-dark;
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: $menthol-dark;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $green;
  }
}

@media only screen and (max-width: 1024px) {
  .btn {
    width: 100%;
  }
}
